<template>
  <div>
    <!-- <v-row>
      <v-col>
        <v-text-field outlined v-model="search_data.name" label="搜尋帳戶名稱" hide-details="auto"></v-text-field>
      </v-col>
      <v-col>
        <div class="d-flex align-center justify-space-between">
          <v-select
            label="帳戶類別"
            :items="search_voting_type"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            v-model="search_data.voting_type"
          ></v-select>
          <v-btn color="primary" class="ml-2" @click="searchVoting">搜尋</v-btn>
        </div>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <router-link :to="{ name: 'add-voting' }">
          <v-btn color="primary">Add Voting</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table-with-pagination
          :headers="voting_header"
          :dataList="voting_list"
          :loading="load_table"
          :options="options"
          :itemLength="total_items"
        ></data-table-with-pagination>
      </v-col>
    </v-row>
    <AlertBox :type="alertBox.type" :text="alertBox.text" :show="alertBox.show"></AlertBox>
  </div>
</template>

<script>
import DataTableWithPagination from '@/components/DataTableWithPagination.vue'
import AlertBox from '@/components/AlertBox'
import { globalFunc } from '@/global'
import { mapActions } from 'vuex'
import { getData, postData } from '@/api'

export default {
  name: 'voting-management',
  components: {
    DataTableWithPagination,
    AlertBox,
  },
  data: () => ({
    isSuperAdmin: false,
    user_center_id: -1,
    verifiedError: false,
    
    voting_header: [
      {
        text: 'Create Date',
        value: 'create_date',
      },
      {
        text: 'Question',
        value: 'question',
      },
      {
        text: 'Voters',
        value: 'voters',
      },
      {
        text: 'Start Date',
        value: 'start_date',
      },
      {
        text: '',
        value: 'view_button',
      },
    ],
    // voting_list: [
    //   { id: 1, create_date: '2021-01-08', question: 'Which show do you prefer', voters: '5', start_date: '2022-01-01', route_link: 'voting-management-detail' },
    //   { id: 2, create_date: '2021-11-18', question: 'Which programme do you prefer', voters: '15', start_date: '2022-02-06', route_link: 'voting-management-detail' },
    // ],
    voting_list: [],
    load_table: true,
    search_data: {
      name: '',
      voting_type: '',
    },
    search_voting_type: [
      {
        'item-text': '所有類別',
        'item-value': '',
      },
      {
        'item-text': '用戶',
        'item-value': 'user',
      },
      {
        'item-text': 'Email',
        'item-value': 'email',
      },
      {
        'item-text': 'Phone',
        'item-value': 'phone',
      },
    ],
    alertBox: {
      show: false,
      text: '',
      type: '',
    },
    total_items: 0,
    current_page: 0,
    current_limit: 10,
    options: {},
  }),
  methods: {
    ...mapActions(['reset']),
    /**
     * initializing the voting data
    */
    async initVotingData() {
      try {
        if (this.verifiedError) {
          return
        }

        this.load_table = true

        let params = new URLSearchParams()
        params.set('get_all_vote', null)

        let result = await getData(params)
        console.log('--- Get voting data ---')
        console.log(result)

        if (result) {
          let temp = []

          for (let i = 0; i < result.length; i++) {
            let send_data = { vote_id: result[i].id }
            let params = new URLSearchParams()
            params.set('get_vote_record_by_vote_id', JSON.stringify(send_data))
            let result2 = await getData(params)
            console.log('--- Get voting result data ---')
            console.log(result2)

            temp.push({
              id: result[i].id,
              create_date: globalFunc.getDateStr(result[i].create_date),
              question: result[i].questions[0].question,
              voters: result2.length,
              start_date: result[i].date,
              route_link: 'voting-management-detail',
            })
          }

          this.voting_list = temp
        }
        this.load_table = false
      } catch (error) {
        console.log('--- Get voting data fail ---')
        this.load_table = false
        this.voting_list = []
        console.log(error)
      }
    },
    /**
     * Searching the voting data
    */
    searchVoting() {
      this.initVotingData(this.search_data.name, this.search_data.voting_type)
    },
    /**
     * Kicking out the user
    */
    kickout() {
      console.log('--- Validation Fail ---')
      this.alertBox.text = '登入憑證過期，3秒後重新導向'
      this.alertBox.type = 'error'
      this.alertBox.show = true

      setTimeout(() => {
        this.alertBox.show = false
        let send_data = []
        send_data['method'] = 'resetData'
        this.reset(send_data)
        this.$router.push({ name: 'Login' })
      }, 3000)
    },

  },
  /**
    * Initializing at the beginning
    * @constructor
  */
  created() {
    setTimeout(async () => {
      console.log('--- Validation ---')
      if (globalFunc.DataValid(this.$store.state.api.login_data)) {
        try {
          this.initVotingData()
        } catch (error) {
          console.log('--- Check User Permission Fail ---')
          console.log(error)
          this.load_table = false
          this.verifiedError = true

          this.alertBox.text = '資料取得失敗'
          this.alertBox.type = 'error'
          this.alertBox.show = true

          setTimeout(() => {
            this.alertBox.show = false
          }, 3000)
        }
      } else {
        this.kickout()
      }
    }, 200)
  },
}
</script>
